.nav {
	&__heading {
		text-align: left;
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&::after  {
			content: "";
			margin-left: 1em;
			border-top: 1px #3b4249 solid;
			flex-grow: 1;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0 -2.25vw;
		list-style-type: none;

		@include min(600px) {
			flex-direction: row;
		}

		@include min($max-container-width) {
			margin-left: -40px;
			margin-right: -40px;
		}
	}

	&__item {
		@include grid(600px 2);
		margin: 0;
		text-align: center;
		text-transform: uppercase;
		font-weight: 500;
		font-family: $font-headings;
		display: flex;
		padding: 2.25vw;
		font-size: rem(26px);

		@include min(1024px) {
			font-size: rem(37px);
		}

		@include min($max-container-width) {
			padding: 40px;
		}

		&.-unavailable {
			.nav__link {
				color: #646e76;
				pointer-events: none;
				cursor: default;

				&::before {
					border-top-color: rgba($color-secondary, 0.3);
				}
			}
		}

		@each $item
			in (
				imune,
				fish,
				booking,
				sky,
				pet,
				money,
				belisario,
				carprogram,
				fond,
				//living
				covid
			)
		{
			&-#{$item} .nav__link::after {
				background-image: url(../images/banner-#{$item}.jpg);
			}
		}
	}

	&__link {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		min-height: 5.4em;
		flex-grow: 1;
		border-radius: 20px;
		overflow: hidden;
		padding: 20px 20px 20px 4vw;
		position: relative;
		letter-spacing: 0.08em;
		transition: box-shadow 0.3s ease-out;
		// box-shadow: 0 0 0 rgba($color-secondary, 0.1);
		box-shadow: 0 20px 50px rgba($color-secondary, 0.1);
		background-color: #1a2025;

		@include min($max-container-width) {
			padding-left: 40px;
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			border-top: 2px $color-secondary solid;
			left: 0;
			right: 0;
		}

		&::after  {
			@include fill;
			border-bottom: 2px $color-secondary solid;
			content: "";
			z-index: 0;
			// opacity: 0;
			opacity: 1;
			border-radius: 20px;
			transition: opacity 0.3s ease-out;
			background: {
				color: #d2d2d2;
				size: cover;
				position: 50% 50%;
				blend-mode: hard-light;
			}
		}

		@include link(&) {
			text-decoration: none;
			color: $color-light;
		}

		/* @include link-over(&) {
			box-shadow: 0 20px 50px rgba($color-secondary, 0.1);

			&::after  {
				opacity: 1;
			}
		} */
	}

	&__text  {
		position: relative;
		z-index: 1;
		transition: transform 0.3s ease-out;
		margin: 0;
		line-height: 1;
		//transform: translateY(0.55em);
		transform: translateY(0);
	}

	/* &__item.-unavailable .nav__text {
		transform: translateY(0);
	} */

	@include link-over(".nav__link") {
		// .nav__text  {
		// 	transform: translateY(0);
		// }
	}

	&__title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		transition: transform 0.2s ease-out, opacity 0.2s ease-out;
		/* opacity: 0;
		transform: translateY(0.75em); */
		font-size: rem(18px);
		margin-top: 0.25em;
		padding-left: 35px;
		opacity: 1;
		transform: translateY(0em);

		@include min(1024px) {
			font-size: rem(22px);
			padding-left: 43px;
		}

		&::after {
			content: encode-svg(
				'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.25 17.25" width="12.25px" height="17.25px"><path stroke="rgb(231, 238, 243)" stroke-width="1.5px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M2.222,1.261 L8.814,8.249 L2.222,15.238 "/></svg>'
			);
			width: 12px;
			height: 17px;
			margin: -3px 0 0 10px;
		}

		.brand {
			color: $color-brand !important;
		}

		.brand-alt {
			color: #1a85e1 !important;
		}
	}

	@include link-over(".nav__link") {
		/* .nav__title {
			opacity: 1;
			transform: translateY(0em);
		} */
	}

	&__cta {
		font-size: rem(22px);
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		line-height: 1.2;
		text-align: left;
		padding-left: 35px;
		position: relative;

		@include min(1024px) {
			padding-left: 43px;
			font-size: rem(28px);
		}
	}

	&__item.-unavailable {
		.nav__title::after {
			content: normal;
		}
	}

	&__label {
		font-size: rem(12px);
		text-transform: uppercase;
		font-family: $font-body;
		position: absolute;
		right: 2em;
		top: 2em;
		z-index: 5;
		color: $color-light;

		+ .nav__label {
			text-align: left;
			right: auto;
			left: 2em;
		}

		&.-sponsored {
			color: #1a85e1;
		}
	}

	&__icon {
		fill: #646e76;
		margin-right: 12px;
		width: 25px;
		height: 25px;
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);

		@include min(1024px) {
			width: 33px;
			height: 33px;
		}
	}
}
