/*==============================
	FONTS
==============================*/

$font-monospace: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono",
	Menlo, Courier, monospace;
$font-body: "Proxima Nova", proxima-nova;
$font-headings: "Proxima Nova", proxima-nova, Poppins, Futura, Montserrat,
	sans-serif;

/*==============================
	COLORS
==============================*/

$color-base: #242424;
$color-brand: #e70082;
$color-secondary: #e80282;
$color-light: #ffffff;
$color-dark: #1a2025;

$color-neutral-100: #f8f8f8;
$color-neutral-200: #f2f2f2;
$color-neutral-300: #e2e2e2;
$color-neutral-400: #cacaca;
$color-neutral-500: #b2b2b2;
$color-neutral-600: #969696;
$color-neutral-700: #7c7c7c;
$color-neutral-800: #545454;
$color-neutral-900: #161616;

/*==============================
	SIZES
==============================*/

$max-container-width: 1180px !global;
$max-width: 1060px !global;
$grid-spacing: 2.5vmin;
$grid-spacing-fixed: 42px;

/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: $color-secondary;
$form-active-text: #ffffff;

$form-item-standard-size: 40px;
$form-item-xl-size: 54px;
$form-item-border-color: #1a2025;
$form-item-border-width: 1px;
$form-item-border-radius: 5px;
$form-item-bg: #1a2025;
$form-item-fg: #ffffff;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, 0.12);
$form-icon-color: #ffffff;

/*==============================
	BUTTONS
==============================*/

$button-radius: 5px;
$button-bg: $color-secondary;
$button-fg: $color-light;
$button-primary-bg: $color-brand;
$button-primary-fg: $color-light;
