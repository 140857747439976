@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
.keyboard-focus:focus {
  outline: 3px #e80282 solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input, .textarea {
  transition: border-color 0.15s ease, box-shadow 0.21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1rem;
  border-radius: 5px;
  border-color: #1a2025;
  border-style: solid;
  border-width: 1px;
  background-color: #1a2025;
  color: #ffffff;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input:focus, .textarea:focus {
  border-color: #e80282;
  outline: 0;
}

.input[disabled], .textarea[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: white;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
.checkbox {
  margin-bottom: 0.6em;
  position: relative;
}

.checkbox .input {
  position: absolute;
  left: -9999rem;
  top: auto;
}

::selection {
  background: #e80282;
  color: #ffffff;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: currentColor;
}

a:hover,
a:focus,
a:active {
  text-decoration-color: #e80282;
}

p a:visited {
  opacity: 0.8;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Proxima Nova", proxima-nova, Poppins, Futura, Montserrat, sans-serif;
  text-transform: uppercase;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1, h2, h3, h4 {
  font-weight: normal;
  line-height: 1.15;
  letter-spacing: 0.12em;
  margin: 0 0 0.85em 0;
}

h5, h6 {
  font-weight: 600;
  margin: 0 0 0.5em 0;
}

.main-heading {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5625rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: normal;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

:focus {
  outline: 0;
}

blockquote {
  padding: 4vw;
  margin: 5vw;
  border-left: 1em #e70082 solid;
  font-size: 1.6em;
  font-style: normal;
  /* cite {
		font-style: normal;
	} */
}

blockquote p {
  margin: 0;
  position: relative;
}

@media screen and (min-width: 800px) {
  blockquote {
    margin: 4vw;
  }
}

@media screen and (min-width: 1200px) {
  blockquote {
    margin: 3em 5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}

.text-bigger {
  font-size: 1.25rem;
  font-weight: bold;
}

code,
pre {
  font-size: 1em;
  font-family: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.pill-button {
  border-radius: 5px;
  padding: 0.55em 1.18em;
  font-size: 1.125rem;
  margin: 2em 0 0;
  font-weight: 500;
  min-height: 40px;
  background-color: #e80282;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  display: inline-block;
}

.pill-button,
.pill-button:link,
.pill-button:visited {
  text-decoration: none;
}

/*
* COMMON BLOCKS
*/
.header {
  position: relative;
  background: #252d34 url(../images/hero.jpg) no-repeat 50% 50%/cover;
  color: #ffffff;
  font-size: 1.125rem;
}

.header__heading {
  font-weight: 500;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.header__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 6vmax;
  padding-bottom: 6vmax;
}

@media screen and (min-width: 800px) {
  .header__content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.header__introduction {
  line-height: 1.44444;
  margin-bottom: 30px;
}

@media screen and (min-width: 800px) {
  .header__introduction {
    flex-basis: 400px;
    flex-grow: 0;
    margin-bottom: 0;
  }
}

.header__user {
  max-width: 300px;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .header__user {
    width: auto;
    flex-basis: 290px;
    flex-grow: 0;
    margin-left: 40px;
  }
}

.header__user-logged-in {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__user-logged-in p {
  margin-bottom: 0;
}

.header__user-icon {
  margin-bottom: 1rem;
}

.header__user-name {
  font-size: 1.125rem;
}

.subpage-header {
  text-align: center;
  padding-top: 6vw;
  margin-bottom: 3em;
}

.subpage-header .header__logo a,
.subpage-header .header__logo a:link,
.subpage-header .header__logo a:visited {
  text-decoration: none;
}

.section-footer {
  background-color: #171d21;
  color: #6e777e;
  margin-top: auto;
}

.section-footer a,
.section-footer a:link,
.section-footer a:visited {
  color: inherit;
}

.section-footer .section__content {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 900px) {
  .section-footer .section__content {
    flex-direction: row;
  }
}

.additional {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.additional > *:not(:last-child) {
  margin-right: 35px;
}

.additional > * {
  margin-bottom: 40px;
}

@media screen and (min-width: 400px) {
  .additional {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (min-width: 700px) {
  .additional {
    flex-wrap: nowrap;
  }
  .additional > * {
    margin-bottom: 0;
  }
}

.additional__heading {
  font-size: 1.125rem;
  color: #ffffff;
}

.contact-info__heading {
  text-transform: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer__logo {
  max-width: 152px;
  height: auto;
}

@media screen and (min-width: 900px) {
  .footer {
    order: -1;
    width: 180px;
    flex-grow: 0;
    flex-direction: column;
    margin-right: 36px;
  }
}

.subpage {
  flex-grow: 1;
  background: transparent url(../images/hero-subpage.jpg) no-repeat 50% 0/100% auto;
}

.subpage__content {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.125rem;
}

/*
* DESIGN COMPONENTS
*/
.contact-form {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.contact-form .help-block.text-danger {
  background: linear-gradient(to top, #252c34, #252c34 50%, transparent 50%, transparent);
}

@media screen and (min-width: 900px) {
  .contact-form {
    width: auto;
    flex-grow: 0;
    flex-basis: 414px;
  }
}

.contact-form .input {
  border-color: #252c34;
  background-color: #252c34;
  color: #ffffff;
}

.contact-form .input:focus {
  border-color: #e80282;
}

.contact-form .input[type="text"],
.contact-form .input[type="email"],
.contact-form textarea.input {
  padding-top: 0.95rem;
  padding-bottom: 0.2rem;
}

.contact-form textarea.input {
  padding-top: 1rem;
  min-height: 98px;
}

.contact-form .label {
  position: absolute;
  left: 1rem;
  top: 50%;
  cursor: text;
  font-size: 1rem;
  transform: translateY(-50%);
  color: #5a6773;
  transition: font-size 0.15s ease-in, transform 0.15s ease-in;
}

.contact-form textarea.input + .label {
  top: 1.3rem;
}

.contact-form .input::placeholder {
  opacity: 0;
}

.contact-form .input:-ms-input-placeholder {
  opacity: 0;
}

.contact-form .input:focus + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.contact-form .input:not(:placeholder-shown):not(:focus) + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.benefitsPage {
  padding-bottom: 100px;
}

.benefitsPage-header {
  text-align: left;
  padding-top: 2em;
  font-size: 18px;
  margin-bottom: 2em;
}

.benefitsPage .button {
  display: inline-block;
}

.benefitsPage h3:not(:first-child) {
  margin-top: 4em;
}

.benefitsPage .button,
.benefitsPage .button:link,
.benefitsPage .button:visited {
  text-decoration: none;
}

.benefitsPage .button:hover,
.benefitsPage .button:focus,
.benefitsPage .button:active {
  background-color: #e70082;
}

.benefitsPage .accented-heading {
  color: #1a85e1;
  text-align: center;
  font-weight: bold;
}

.benefitsPage .centered {
  text-align: center;
}

.benefitsPage .accented {
  color: #1a85e1;
}

.benefitsPage .block {
  display: block;
  margin-top: 10px;
  font-style: italic;
}

.benefitsPage .thumbs-up {
  margin-top: 30px;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0 50px;
}

.benefits-list li {
  display: flex;
  align-items: center;
  margin: 0 0 0.5em;
}

.benefits-list li::before {
  content: url("../images/icons8-checkmark.svg");
  width: 24px;
  height: 24px;
  margin-right: 1em;
}

.benefits-how-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  margin: 0 0 25px;
}

@media screen and (min-width: 800px) {
  .benefits-how-list {
    flex-direction: row;
    align-items: flex-start;
  }
}

.benefits-how-list li {
  width: 100%;
  margin: 0 0 20px;
  box-sizing: border-box;
  padding: 80px 0 0;
  background-repeat: no-repeat;
  background-position: 50% 0;
}

@media screen and (min-width: 800px) {
  .benefits-how-list li {
    width: 33.333334%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.benefits-how-list li:nth-child(1) {
  background-image: url(../images/icons8-circled-1.svg);
}

.benefits-how-list li:nth-child(2) {
  background-image: url(../images/icons8-circled-2.svg);
}

.benefits-how-list li:nth-child(3) {
  background-image: url(../images/icons8-circled-3.svg);
}

.benefits-how-list strong {
  font-size: 20px;
  color: #1a85e1;
  margin-bottom: 0.5em;
  display: block;
}

.benefits-keys-list {
  list-style-type: none;
  margin: 0 0 25px;
  padding: 0;
  text-align: left;
}

.benefits-keys-list li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em 0;
}

.benefits-keys-list li p {
  margin-bottom: 0;
  margin-top: 0.5em;
  line-height: 1.2;
}

.benefits-keys-list li img {
  flex-shrink: 0;
  margin: 0 1em 0;
}

.benefits-keys-list strong {
  font-size: 20px;
  color: #1a85e1;
  margin-bottom: 0.5em;
  display: block;
}

.benefits-cta span {
  color: #1a85e1;
  font-style: italic;
}

.benefits-cta .button {
  font-style: normal;
  margin-right: 1em;
}

.form {
  padding: 0;
  max-width: 80rem;
  /* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}

.form input[type="button"],
.form input[type="submit"],
.form button {
  cursor: pointer;
}

.form__errors  {
  list-style-type: none;
  margin: 1.5em 0;
  padding: 0;
  font-size: 1rem;
}

.form__link {
  font-size: 0.75rem;
}

.form__error  {
  color: #db355a;
}

.form__columns {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.form__columns > .form__section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-basis: 50%;
}

.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 14px;
  position: relative;
}

.form__section .help-block.text-danger  {
  color: #a00e2b;
  position: absolute;
  right: 0.75em;
  top: 0;
  padding-left: 5px;
  padding-right: 5px;
  transform: translateY(-50%);
  font-size: 0.75rem;
  background: linear-gradient(to top, #1a2025, #1a2025 50%, transparent 50%, transparent);
  border-radius: 3px;
  text-align: right;
}

.form__section .checkbox .help-block.text-danger {
  position: static;
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form__section.-to-end {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.form__section.-spaced {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form__section.-spaced > span:last-child {
  width: 100% !important;
}

.single-form {
  width: 100%;
  max-width: 315px;
  margin-left: auto;
  margin-right: auto;
}

.single-form .checkbox [type="checkbox"] + span {
  color: #6e777e;
}

.single-form .checkbox [type="checkbox"] + span::before {
  background-color: #2f3b45;
}

.single-form .input {
  border-color: #1a2025;
  background-color: #1a2025;
  color: #ffffff;
}

.single-form .input:focus {
  border-color: #e80282;
}

.single-form .input[type="text"],
.single-form .input[type="password"],
.single-form .input[type="email"],
.single-form textarea.input {
  padding-top: 0.95rem;
  padding-bottom: 0.2rem;
}

.single-form textarea.input {
  padding-top: 1rem;
  min-height: 98px;
}

.single-form .label {
  position: absolute;
  left: 1rem;
  top: 50%;
  cursor: text;
  font-size: 1rem;
  transform: translateY(-50%);
  color: #5a6773;
  transition: font-size 0.15s ease-in, transform 0.15s ease-in;
}

.single-form textarea.input + .label {
  top: 1.3rem;
}

.single-form .input::placeholder {
  opacity: 0;
}

.single-form .input:-ms-input-placeholder {
  opacity: 0;
}

.single-form .input:focus + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.single-form .input:not(:placeholder-shown):not(:focus) + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.slider-wrapper {
  overflow: hidden;
}

.section-banner {
  padding-top: 0;
  margin-top: -8.2rem;
}

.slider-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 5vmax;
  padding-right: 5vmax;
  margin-bottom: 20px;
  /* border: {
		width: 1px 1px 0 1px;
		color: rgba(white, 0.1);
		style: solid;
		radius: 20px 20px 0 0;
	} */
}

.slider-top .section__heading {
  margin: 1.5em 0;
  text-align: left;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.slider-top .section__heading::after  {
  content: "";
  margin-left: 1em;
  border-top: 1px #3b4249 solid;
  flex-grow: 1;
}

@media screen and (min-width: 800px) {
  .slider-top .section__heading {
    margin: 2em 0;
  }
}

.slider-nav {
  display: inline-flex;
  align-items: center;
}

.slider-nav__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  padding: 0;
  color: #ffffff;
}

.slider-nav__button svg {
  stroke: currentColor;
}

.slider-nav__button:hover, .slider-nav__button:focus {
  color: #e80282;
}

.slider-nav__button:first-child {
  margin-right: 16px;
}

.slider-nav__button:last-child {
  margin-left: 16px;
}

.slider-nav__indicator {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
}

.slider-nav__indicator.-current {
  background-color: #e70082;
}

.slider {
  display: flex;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  border-width: 2px 0;
  border-color: #e70082;
  border-style: solid;
}

.slider::after {
  content: "";
  pointer-events: none;
  position: absolute;
  left: 45%;
  top: 0;
  bottom: 2rem;
  transform: translateX(-50%);
  max-width: 1180px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  /* border: {
			width: 0 1px 1px 1px;
			color: rgba(white, 0.1);
			style: solid;
			radius: 0 0 20px 20px;
		} */
}

@media screen and (min-width: 1180px) {
  .slider::after {
    left: 50%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.slider__logo {
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.slider__logo .logo-g32 {
  width: 82px;
}

.slider__logo .logo-gfish {
  width: 100px;
}

.slider__logo .logo-habakuk {
  width: 32px;
}

@media screen and (min-width: 600px) {
  .slider__logo .logo-g32 {
    width: 120px;
  }
  .slider__logo .logo-gfish {
    width: 150px;
  }
  .slider__logo .logo-habakuk {
    width: 50px;
  }
}

.slider__logo img + img {
  margin-left: 1em;
}

@media screen and (min-width: 600px) {
  .slider__logo img + img {
    margin-left: 2em;
  }
}

.slider__vendor {
  font-size: 1.625rem;
  margin: 0;
  line-height: 1.1;
  /* .slider__icon {
			fill: currentColor;
			margin-right: 6px;
		} */
}

@media screen and (min-width: 600px) {
  .slider__vendor {
    font-size: 2.125rem;
  }
}

.slider__vendor b {
  font-weight: bold;
  color: #ef0c90;
}

.slider__vendor .hoo {
  color: #72cffa;
}

.slider__text {
  margin: 0.3em 0;
  line-height: 1.1;
  /* .slider__value {
			display: inline-block;
			font-size: rem(24px);
			font-weight: bold;
			border-radius: 20px;
			border: 2px white solid;
			text-transform: uppercase;
			padding: 0.3em 0.6em;
		} */
}

.slider__slide {
  width: 100%;
  flex: 1 0 100%;
  position: relative;
  font-size: 1.25rem;
  overflow: hidden;
  left: -100%;
  min-height: 10em;
  /* background: {
			position: 50% 0;
			repeat: no-repeat;
			size: cover;
		} */
  /* &#slide-3  {
			background-image: url(../images/banner-static-1.jpg);
		} */
}

@media screen and (min-width: 600px) {
  .slider__slide {
    font-size: 1.5625rem;
  }
}

.slider__slide .unillax-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slider__slide .unillax-background {
  background-size: cover;
  background-repeat: no-repeat;
}

.slider__slide#slide-1 .unillax-background {
  background-image: url(../images/banner-parallax-1.jpg);
  background-position: center;
}

.slider__slide#slide-2 .unillax-background {
  background-image: url(../images/banner-parallax-2.jpg);
  background-position: center;
}

.slider__slide#slide-3 .unillax-background {
  background-image: url(../images/banner-parallax-3-mobile.jpg);
  background-position: right center;
}

@media screen and (min-width: 600px) {
  .slider__slide#slide-3 .unillax-background {
    background-image: url(../images/banner-parallax-3.jpg);
    background-position: center;
  }
}

.slider__slide#slide-3 .slider__text {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.slider__slide#slide-3 .slider__text small {
  display: block;
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: none;
  margin-top: 0.6em;
}

.slider__slide .slider__vendor,
.slider__slide .slider__text {
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 1024px) {
  .slider__vendor {
    font-size: 2.75rem;
    margin-bottom: 0;
  }
  .slider__slide {
    font-size: 2rem;
  }
}

.slider.js-slider {
  overflow: hidden;
}

.slider.js-slider .slider__slide {
  transition: transform 0.4s ease-in-out;
  transform: translateX(calc(var(--index) * -100%));
}

.slider.js-slider.-end .slider__slide, .slider.js-slider.-begin .slider__slide {
  transition: none !important;
}

.slider .slide__content {
  padding: 1rem 0;
  text-align: left;
  height: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  .slider .slide__content {
    padding-left: 5vmax;
    padding-right: 5vmax;
  }
}

.slider .slide__banner  {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.nav {
  /* &__item.-unavailable .nav__text {
		transform: translateY(0);
	} */
}

.nav__heading {
  text-align: left;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav__heading::after  {
  content: "";
  margin-left: 1em;
  border-top: 1px #3b4249 solid;
  flex-grow: 1;
}

.nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -2.25vw;
  list-style-type: none;
}

@media screen and (min-width: 600px) {
  .nav__list {
    flex-direction: row;
  }
}

@media screen and (min-width: 1180px) {
  .nav__list {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.nav__item {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Proxima Nova", proxima-nova, Poppins, Futura, Montserrat, sans-serif;
  display: flex;
  padding: 2.25vw;
  font-size: 1.625rem;
}

@media screen and (max-width: 600px) {
  .nav__item {
    width: 100%;
  }
}

@media screen and (min-width: 599px) {
  .nav__item {
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .nav__item {
    font-size: 2.3125rem;
  }
}

@media screen and (min-width: 1180px) {
  .nav__item {
    padding: 40px;
  }
}

.nav__item.-unavailable .nav__link {
  color: #646e76;
  pointer-events: none;
  cursor: default;
}

.nav__item.-unavailable .nav__link::before {
  border-top-color: rgba(232, 2, 130, 0.3);
}

.nav__item-imune .nav__link::after {
  background-image: url(../images/banner-imune.jpg);
}

.nav__item-fish .nav__link::after {
  background-image: url(../images/banner-fish.jpg);
}

.nav__item-booking .nav__link::after {
  background-image: url(../images/banner-booking.jpg);
}

.nav__item-sky .nav__link::after {
  background-image: url(../images/banner-sky.jpg);
}

.nav__item-pet .nav__link::after {
  background-image: url(../images/banner-pet.jpg);
}

.nav__item-money .nav__link::after {
  background-image: url(../images/banner-money.jpg);
}

.nav__item-belisario .nav__link::after {
  background-image: url(../images/banner-belisario.jpg);
}

.nav__item-carprogram .nav__link::after {
  background-image: url(../images/banner-carprogram.jpg);
}

.nav__item-fond .nav__link::after {
  background-image: url(../images/banner-fond.jpg);
}

.nav__item-covid .nav__link::after {
  background-image: url(../images/banner-covid.jpg);
}

.nav__link {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 5.4em;
  flex-grow: 1;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 20px 20px 4vw;
  position: relative;
  letter-spacing: 0.08em;
  transition: box-shadow 0.3s ease-out;
  box-shadow: 0 20px 50px rgba(232, 2, 130, 0.1);
  background-color: #1a2025;
  /* @include link-over(&) {
			box-shadow: 0 20px 50px rgba($color-secondary, 0.1);

			&::after  {
				opacity: 1;
			}
		} */
}

@media screen and (min-width: 1180px) {
  .nav__link {
    padding-left: 40px;
  }
}

.nav__link::before {
  content: "";
  position: absolute;
  bottom: 0;
  border-top: 2px #e80282 solid;
  left: 0;
  right: 0;
}

.nav__link::after  {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 2px #e80282 solid;
  content: "";
  z-index: 0;
  opacity: 1;
  border-radius: 20px;
  transition: opacity 0.3s ease-out;
  background-color: #d2d2d2;
  background-size: cover;
  background-position: 50% 50%;
  background-blend-mode: hard-light;
}

.nav__link, .nav__link:link, .nav__link:visited {
  text-decoration: none;
  color: #ffffff;
}

.nav__text  {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease-out;
  margin: 0;
  line-height: 1;
  transform: translateY(0);
}

.nav__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  /* opacity: 0;
		transform: translateY(0.75em); */
  font-size: 1.125rem;
  margin-top: 0.25em;
  padding-left: 35px;
  opacity: 1;
  transform: translateY(0em);
}

@media screen and (min-width: 1024px) {
  .nav__title {
    font-size: 1.375rem;
    padding-left: 43px;
  }
}

.nav__title::after {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.25 17.25' width='12.25px' height='17.25px'%3E%3Cpath stroke='rgb(231, 238, 243)' stroke-width='1.5px' stroke-linecap='butt' stroke-linejoin='miter' fill='none' d='M2.222,1.261 L8.814,8.249 L2.222,15.238 '/%3E%3C/svg%3E");
  width: 12px;
  height: 17px;
  margin: -3px 0 0 10px;
}

.nav__title .brand {
  color: #e70082 !important;
}

.nav__title .brand-alt {
  color: #1a85e1 !important;
}

.nav .nav__link:hover,
.nav .nav__link:focus,
.nav .nav__link:active {
  /* .nav__title {
			opacity: 1;
			transform: translateY(0em);
		} */
}

.nav__cta {
  font-size: 1.375rem;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  line-height: 1.2;
  text-align: left;
  padding-left: 35px;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .nav__cta {
    padding-left: 43px;
    font-size: 1.75rem;
  }
}

.nav__item.-unavailable .nav__title::after {
  content: normal;
}

.nav__label {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "Proxima Nova", proxima-nova;
  position: absolute;
  right: 2em;
  top: 2em;
  z-index: 5;
  color: #ffffff;
}

.nav__label + .nav__label {
  text-align: left;
  right: auto;
  left: 2em;
}

.nav__label.-sponsored {
  color: #1a85e1;
}

.nav__icon {
  fill: #646e76;
  margin-right: 12px;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 1024px) {
  .nav__icon {
    width: 33px;
    height: 33px;
  }
}

.login-form .input {
  border-color: #1a2025;
  background-color: #1a2025;
  color: #ffffff;
}

.login-form .input:focus {
  border-color: #e80282;
}

.login-form .input[type="text"], .login-form .input[type="email"] {
  padding-top: 0.95rem;
  padding-bottom: 0.2rem;
}

.login-form .help-block.text-danger {
  background: linear-gradient(to top, #1a2025, #1a2025 50%, transparent 50%, transparent);
}

.login-form .label {
  position: absolute;
  left: 1rem;
  top: 50%;
  cursor: text;
  font-size: 1rem;
  transform: translateY(-50%);
  color: #5a6773;
  transition: font-size 0.15s ease-in, transform 0.15s ease-in;
}

.login-form .input::placeholder {
  opacity: 0;
}

.login-form .input:-ms-input-placeholder {
  opacity: 0;
}

.login-form .input:focus + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.login-form .input:not(:placeholder-shown):not(:focus) + .label {
  font-size: 0.75rem;
  transform: translateY(-1.25rem);
}

.input {
  min-height: 40px;
  padding: 0.3em 1em;
}

.input.has-error, .input.has-error:focus {
  border-color: #a00e2b !important;
}

.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.input::-webkit-credentials-auto-fill-button {
  background-color: currentColor;
}

.input::-webkit-credentials-auto-fill-button:hover {
  background-color: #e80282;
}

.input::-webkit-contacts-auto-fill-button {
  background-color: currentColor;
}

.input::-webkit-contacts-auto-fill-button:hover {
  background-color: #e80282;
}

.textarea {
  padding: .5em 1em;
  resize: vertical;
  height: auto;
  min-height: 10em;
}

.button {
  border-radius: 5px;
  padding: 0.55em 1.18em;
  font-size: 1.125rem;
  margin: 0;
  font-weight: 500;
  min-height: 40px;
  background-color: #e80282;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  appearance: none;
  border: 0;
  /* &[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}

	&.-icon {
		&::before {
			vertical-align: middle;
			margin-right: .75em;
			display: inline-block;
		}
	}

	&.-primary {
		background-color: $button-primary-bg;
		box-shadow: 0 0 0 0 $button-primary-bg;
		color: $button-primary-fg;

		&:not([disabled]) {
			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 5px 20px $button-primary-bg;
			}
		}
	} */
}

.button[data-status="done"], .button[data-status="loading"] {
  background-image: url(../images/spinner.svg);
  background-position: 50% 50%;
  background-size: 36px;
  background-repeat: no-repeat;
  color: transparent;
}

.button[data-status="done"] {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22px' height='19px'%3E%3Cpath fill-rule='evenodd'  stroke='rgb(255, 255, 255)' stroke-width='2px' stroke-linecap='butt' stroke-linejoin='miter' fill='none' d='M1.881,7.053 L8.069,15.032 L19.119,2.167 '/%3E%3C/svg%3E");
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.button:nth-last-child(n+2), .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.button:not(:first-child):not(.-block) {
  margin-left: 0.5em;
}

.checkbox [type="checkbox"] {
  position: absolute;
  left: -999rem;
}

.checkbox [type="checkbox"] + span {
  display: inline-flex;
  align-items: center;
  font-size: 0.8125rem;
  max-width: 18em;
}

.checkbox [type="checkbox"] + span::before {
  content: "";
  border: 0;
  margin: 0 18px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #252c34;
  background-image: none;
  background-position: center;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.checkbox [type="checkbox"]:checked + span::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='14px'%3E%3Cpath fill-rule='evenodd'  stroke='%23e80282' stroke-width='2px' stroke-linecap='butt' stroke-linejoin='miter' fill='none' d='M2.628,5.342 L6.125,9.788 L12.371,2.619 '/%3E%3C/svg%3E");
}
