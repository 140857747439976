.contact-form {
	width: 100%;
	margin-left: 0;
	margin-right: 0;

	.help-block.text-danger {
		background: linear-gradient(
			to top,
			#252c34,
			#252c34 50%,
			transparent 50%,
			transparent
		);
	}

	@include min(900px) {
		width: auto;
		flex-grow: 0;
		flex-basis: 414px;
	}

	.input {
		border-color: #252c34;
		background-color: #252c34;
		color: $color-light;

		&:focus {
			border-color: $form-active-color;
		}
	}

	.input[type="text"],
	.input[type="email"],
	textarea.input {
		padding-top: 0.95rem;
		padding-bottom: 0.2rem;
	}

	textarea.input {
		padding-top: 1rem;
		min-height: 98px;
	}

	.label {
		position: absolute;
		left: 1rem;
		top: 50%;
		cursor: text;
		font-size: rem(16px);
		transform: translateY(-50%);
		color: #5a6773;
		transition: font-size 0.15s ease-in, transform 0.15s ease-in;
	}

	textarea.input + .label {
		top: 1.3rem;
	}

	.input::placeholder {
		opacity: 0;
	}

	.input:-ms-input-placeholder {
		opacity: 0;
	}

	.input:focus + .label {
		font-size: rem(12px);
		transform: translateY(-1.25rem);
	}

	.input:not(:placeholder-shown):not(:focus) + .label {
		font-size: rem(12px);
		transform: translateY(-1.25rem);
	}
}
