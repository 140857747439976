::selection {
	background: $color-secondary;
	color: $color-light;
}

@include link {
	color: inherit;
	text-decoration: underline;
	text-decoration-color: currentColor;
}

@include link-over {
	//color: $color-brand;
	text-decoration-color: $color-secondary;
}

@include link-visited("p a") {
	opacity: 0.8;
}

@include headings(1, 6) {
	font-family: $font-headings;
	text-transform: uppercase;

	&:first-child {
		margin-top: 0;
	}
}

@include headings(1, 4) {
	font-weight: normal;
	line-height: 1.15;
	letter-spacing: 0.12em;
	margin: 0 0 0.85em 0;
}

@include headings(5, 6) {
	font-weight: 600;
	margin: 0 0 0.5em 0;
}

.main-heading {
	font-size: 3rem;
	font-weight: bold;
}

h2 {
	font-size: rem(25px);
}

h3 {
	font-size: rem(20px);
	font-weight: normal;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

:focus {
	outline: 0;
}

.keyboard-focus:focus {
	@extend %default-outline;
}

blockquote {
	padding: 4vw;
	margin: 5vw;
	border-left: 1em $color-brand solid;
	font-size: 1.6em;
	font-style: normal;

	/* cite {
		font-style: normal;
	} */

	p {
		margin: 0;
		position: relative;
	}

	@include min(800px) {
		margin: 4vw;
	}

	@include min(1200px) {
		margin: 3em 5em;
		padding-left: 4em;
		padding-right: 4em;
	}
}

.text-bigger {
	font-size: rem(20px);
	font-weight: bold;
}

code,
pre {
	font-size: 1em;
	font-family: $font-monospace;
}

.pill-button {
	border-radius: $button-radius;
	padding: 0.55em 1.18em;
	font-size: rem(18px);
	margin: 2em 0 0;
	font-weight: 500;
	min-height: 40px;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color 0.15s ease, box-shadow 0.15s ease;
	position: relative;
	box-shadow: none;
	display: inline-block;
}

@include link(".pill-button") {
	text-decoration: none;
}
