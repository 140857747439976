.benefitsPage {
	padding-bottom: 100px;

	&-header {
		text-align: left;
		padding-top: 2em;
		font-size: 18px;
		margin-bottom: 2em;
	}

	.button {
		display: inline-block;
	}

	h3 {
		&:not(:first-child) {
			margin-top: 4em;
		}
	}

	@include link(".button") {
		text-decoration: none;
	}

	@include link-over(".button") {
		background-color: $color-brand;
	}

	.accented-heading {
		color: #1a85e1;
		text-align: center;
		font-weight: bold;
	}

	.centered {
		text-align: center;
	}

	.accented {
		color: #1a85e1;
	}

	.block {
		display: block;
		margin-top: 10px;
		font-style: italic;
	}

	.thumbs-up {
		margin-top: 30px;
	}
}

.benefits {
	&-list {
		list-style-type: none;
		padding: 0;
		margin: 20px 0 50px;

		li {
			display: flex;
			align-items: center;
			margin: 0 0 0.5em;

			&::before {
				content: url("../images/icons8-checkmark.svg");
				width: 24px;
				height: 24px;
				margin-right: 1em;
			}
		}
	}

	&-how-list {
		list-style-type: none;
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		flex-direction: column;
		padding: 0;
		margin: 0 0 25px;

		@include min(800px) {
			flex-direction: row;
			align-items: flex-start;
		}

		li {
			width: 100%;
			margin: 0 0 20px;
			box-sizing: border-box;
			padding: 80px 0 0;
			background-repeat: no-repeat;
			background-position: 50% 0;

			@include min(800px) {
				width: 33.333334%;
				padding-left: 15px;
				padding-right: 15px;
			}

			&:nth-child(1) {
				background-image: url(../images/icons8-circled-1.svg);
			}

			&:nth-child(2) {
				background-image: url(../images/icons8-circled-2.svg);
			}

			&:nth-child(3) {
				background-image: url(../images/icons8-circled-3.svg);
			}
		}

		strong {
			font-size: 20px;
			color: #1a85e1;
			margin-bottom: 0.5em;
			display: block;
		}
	}

	&-keys-list {
		list-style-type: none;
		margin: 0 0 25px;
		padding: 0;
		text-align: left;

		li {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 1em 0;

			p {
				margin-bottom: 0;
				margin-top: 0.5em;
				line-height: 1.2;
			}

			img {
				flex-shrink: 0;
				margin: 0 1em 0;
			}
		}

		strong {
			font-size: 20px;
			color: #1a85e1;
			margin-bottom: 0.5em;
			display: block;
		}
	}

	&-cta {
		span {
			color: #1a85e1;
			font-style: italic;
		}

		.button {
			font-style: normal;
			margin-right: 1em;
		}
	}
}
