/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars",
	"abstracts/animations", "abstracts/templates";

@import "base/typography";

/*
* COMMON BLOCKS
*/

@import "layout/header", "layout/footer";

.subpage {
	flex-grow: 1;
	background: transparent url(../images/hero-subpage.jpg) no-repeat 50% 0 /
		100% auto;

	&__content {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		font-size: rem(18px);
	}
}

/*
* DESIGN COMPONENTS
*/

@import //"components/search-form",
	"components/contact-form",
	"components/benefits",
	//"components/search",
	//"components/menu",
	//"components/social",
	"components/form",
	"components/single-form", "components/slider", "components/nav",
	"components/login-form", "components/input", "components/textarea",
	"components/button", "components/checkbox";
