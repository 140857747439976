.header {
	position: relative;
	background: #252d34 url(../images/hero.jpg) no-repeat 50% 50% / cover;
	color: $color-light;
	font-size: rem(18px);
	//padding-bottom: 9rem;

	&__heading {
		font-weight: 500;
		font-size: rem(18px);
		text-transform: uppercase;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding-top: 6vmax;
		padding-bottom: 6vmax;

		@include min(800px) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__introduction {
		line-height: (26 / 18);
		margin-bottom: 30px;

		@include min(800px) {
			flex-basis: 400px;
			flex-grow: 0;
			margin-bottom: 0;
		}
	}

	&__user {
		max-width: 300px;
		width: 100%;

		@include min(800px) {
			width: auto;
			flex-basis: 290px;
			flex-grow: 0;
			margin-left: 40px;
		}

		&-logged-in {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				margin-bottom: 0;
			}
		}

		&-icon {
			margin-bottom: 1rem;
		}

		&-name {
			font-size: rem(18px);
		}
	}
}

.subpage-header {
	text-align: center;
	padding-top: 6vw;
	margin-bottom: 3em;

	.header__logo {
		@include link {
			text-decoration: none;
		}
	}
}
