.section-footer {
	background-color: #171d21;
	color: #6e777e;
	margin-top: auto;

	@include link {
		color: inherit;
	}

	.section__content {
		display: flex;
		flex-direction: column;

		@include min(900px) {
			flex-direction: row;
		}
	}
}

.additional {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	> *:not(:last-child) {
		margin-right: 35px;
	}

	> * {
		margin-bottom: 40px;
	}

	@include min(400px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@include min(700px) {
		flex-wrap: nowrap;

		> * {
			margin-bottom: 0;
		}
	}

	&__heading {
		font-size: rem(18px);
		color: $color-light;
	}
}

.contact-info__heading {
	text-transform: none;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	&__logo {
		max-width: 152px;
		height: auto;
	}

	@include min(900px) {
		order: -1;
		width: 180px;
		flex-grow: 0;
		flex-direction: column;
		margin-right: 36px;
	}
}
