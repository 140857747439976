.login-form {
	.input {
		border-color: #1a2025;
		background-color: #1a2025;
		color: $color-light;

		&:focus {
			border-color: $form-active-color;
		}

		&[type="text"],
		&[type="email"] {
			padding-top: 0.95rem;
			padding-bottom: 0.2rem;
		}
	}

	.help-block.text-danger {
		background: linear-gradient(
			to top,
			#1a2025,
			#1a2025 50%,
			transparent 50%,
			transparent
		);
	}

	.label {
		position: absolute;
		left: 1rem;
		top: 50%;
		cursor: text;
		font-size: rem(16px);
		transform: translateY(-50%);
		color: #5a6773;
		transition: font-size 0.15s ease-in, transform 0.15s ease-in;
	}

	.input::placeholder {
		opacity: 0;
	}

	.input:-ms-input-placeholder {
		opacity: 0;
	}

	.input:focus + .label {
		font-size: rem(12px);
		transform: translateY(-1.25rem);
	}

	.input:not(:placeholder-shown):not(:focus) + .label {
		font-size: rem(12px);
		transform: translateY(-1.25rem);
	}
}
