.checkbox {
	@extend %form-element-switchable-template;

	[type="checkbox"] {
		position: absolute;
		left: -999rem;

		+ span {
			display: inline-flex;
			align-items: center;
			font-size: rem(13px);
			max-width: 18em;
		}

		+ span::before {
			content: "";
			border: 0;
			margin: 0 18px 0 0;
			width: 15px;
			height: 15px;
			border-radius: 3px;
			flex-shrink: 0;
			flex-grow: 0;
			background: {
				color: #252c34;
				image: none;
				position: center;
				size: 12px auto;
				repeat: no-repeat;
			}
		}

		&:checked + span::before {
			background-image: encode-svg(
				'<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="14px"><path fill-rule="evenodd"  stroke="#{$color-secondary}" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M2.628,5.342 L6.125,9.788 L12.371,2.619 "/></svg>'
			);
		}
	}
}
