.slider-wrapper {
	$padding: $max-container-width - $max-width;
	overflow: hidden;
}

.section-banner {
	padding-top: 0;
	margin-top: -8.2rem;
}

.slider-top {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 5vmax;
	padding-right: 5vmax;
	margin-bottom: 20px;
	/* border: {
		width: 1px 1px 0 1px;
		color: rgba(white, 0.1);
		style: solid;
		radius: 20px 20px 0 0;
	} */

	.section__heading {
		margin: 1.5em 0;
		text-align: left;
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;

		&::after  {
			content: "";
			margin-left: 1em;
			border-top: 1px #3b4249 solid;
			flex-grow: 1;
		}

		@include min(800px) {
			margin: 2em 0;
		}
	}
}

.slider-nav {
	display: inline-flex;
	align-items: center;

	&__button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		box-shadow: none;
		border: 0;
		outline: 0;
		border-radius: 0;
		background-color: transparent;
		background-image: none;
		padding: 0;
		color: $color-light;

		svg {
			stroke: currentColor;
		}

		&:hover,
		&:focus {
			color: $color-secondary;
		}

		&:first-child {
			margin-right: 16px;
		}

		&:last-child {
			margin-left: 16px;
		}
	}

	&__indicator {
		display: inline-block;
		width: 7px;
		height: 7px;
		margin: 0 5px;
		background-color: white;
		border-radius: 50%;

		&.-current {
			background-color: $color-brand;
		}
	}
}

.slider {
	display: flex;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow-x: auto;
	position: relative;
	border: {
		width: 2px 0;
		color: $color-brand;
		style: solid;
	}

	&::after {
		content: "";
		pointer-events: none;
		position: absolute;
		left: 45%;
		top: 0;
		bottom: 2rem;
		transform: translateX(-50%);
		max-width: $max-container-width;
		width: 90%;
		margin-left: 5%;
		margin-right: 5%;
		/* border: {
			width: 0 1px 1px 1px;
			color: rgba(white, 0.1);
			style: solid;
			radius: 0 0 20px 20px;
		} */

		@include min($max-container-width) {
			left: 50%;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__logo {
		position: relative;
		margin: 0;
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		.logo-g32 {
			width: 82px;
		}

		.logo-gfish {
			width: 100px;
		}

		.logo-habakuk {
			width: 32px;
		}

		@include min(600px) {
			.logo-g32 {
				width: 120px;
			}

			.logo-gfish {
				width: 150px;
			}

			.logo-habakuk {
				width: 50px;
			}
		}

		img + img {
			margin-left: 1em;

			@include min(600px) {
				margin-left: 2em;
			}
		}
	}

	&__vendor {
		font-size: rem(26px);
		margin: 0;
		line-height: 1.1;
		//text-transform: uppercase;

		@include min(600px) {
			font-size: rem(34px);
		}

		b {
			font-weight: bold;
			color: #ef0c90;
		}

		.hoo {
			color: #72cffa;
		}

		/* .slider__icon {
			fill: currentColor;
			margin-right: 6px;
		} */
	}

	&__text {
		margin: 0.3em 0;
		line-height: 1.1;

		/* .slider__value {
			display: inline-block;
			font-size: rem(24px);
			font-weight: bold;
			border-radius: 20px;
			border: 2px white solid;
			text-transform: uppercase;
			padding: 0.3em 0.6em;
		} */
	}

	&__slide {
		width: 100%;
		flex: 1 0 100%;
		position: relative;
		font-size: rem(20px);
		overflow: hidden;
		left: -100%;
		min-height: 10em;

		@include min(600px) {
			font-size: rem(25px);
		}
		/* background: {
			position: 50% 0;
			repeat: no-repeat;
			size: cover;
		} */

		.unillax-container {
			@include fill;
		}

		.unillax-background {
			background-size: cover;
			background-repeat: no-repeat;
		}

		&#slide-1 {
			.unillax-background {
				background-image: url(../images/banner-parallax-1.jpg);
				background-position: center;
			}
		}

		&#slide-2 .unillax-background {
			background-image: url(../images/banner-parallax-2.jpg);
			background-position: center;
		}

		&#slide-3 {
			.unillax-background {
				background-image: url(../images/banner-parallax-3-mobile.jpg);
				background-position: right center;

				@include min(600px) {
					background-image: url(../images/banner-parallax-3.jpg);
					background-position: center;
				}
			}

			.slider__text {
				color: black;
				text-transform: uppercase;
				font-weight: bold;

				small {
					display: block;
					font-size: rem(18px);
					font-weight: normal;
					text-transform: none;
					margin-top: 0.6em;
				}
			}
		}

		.slider__vendor,
		.slider__text {
			position: relative;
			z-index: 5;
		}

		/* &#slide-3  {
			background-image: url(../images/banner-static-1.jpg);
		} */
	}

	@include min(1024px) {
		&__vendor {
			font-size: rem(44px);
			margin-bottom: 0;
		}

		&__slide {
			font-size: rem(32px);
		}
	}

	&.js-slider {
		overflow: hidden;

		.slider__slide {
			transition: transform 0.4s ease-in-out;
			transform: translateX(calc(var(--index) * -100%));
		}

		&.-end,
		&.-begin {
			.slider__slide {
				transition: none !important;
			}
		}
	}

	.slide__content {
		padding: 1rem 0;
		text-align: left;
		height: 10em;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include min(800px) {
			padding-left: 5vmax;
			padding-right: 5vmax;
		}
	}

	.slide__banner  {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		z-index: -1;
	}
}
