.button {
	border-radius: $button-radius;
	padding: 0.55em 1.18em;
	font-size: rem(18px);
	margin: 0;
	font-weight: 500;
	min-height: 40px;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color 0.15s ease, box-shadow 0.15s ease;
	position: relative;
	box-shadow: none;
	appearance: none;
	border: 0;

	&[data-status="done"],
	&[data-status="loading"] {
		background-image: url(../images/spinner.svg);
		background-position: 50% 50%;
		background-size: 36px;
		background-repeat: no-repeat;
		color: transparent;
	}

	&[data-status="done"] {
		background-image: encode-svg(
			'<svg xmlns="http://www.w3.org/2000/svg" width="22px" height="19px"><path fill-rule="evenodd"  stroke="rgb(255, 255, 255)" stroke-width="2px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.881,7.053 L8.069,15.032 L19.119,2.167 "/></svg>'
		);
	}

	&:not([disabled]) {
		&:hover,
		&:focus,
		&:active {
			z-index: 3;
			outline: 0;
			//box-shadow: 0 5px 20px $button-bg;
		}
	}

	@include quantity-query(min 2) {
		margin-bottom: 0.5em;
	}

	&:not(:first-child):not(.-block) {
		margin-left: 0.5em;
	}

	/* &[disabled] {
		color: #999999;
		background-color: #ebebeb;
		cursor: not-allowed;
	}

	&.-icon {
		&::before {
			vertical-align: middle;
			margin-right: .75em;
			display: inline-block;
		}
	}

	&.-primary {
		background-color: $button-primary-bg;
		box-shadow: 0 0 0 0 $button-primary-bg;
		color: $button-primary-fg;

		&:not([disabled]) {
			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 5px 20px $button-primary-bg;
			}
		}
	} */
}
