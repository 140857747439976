.input {
	@extend %form-element-template;
	min-height: $form-item-standard-size;
	padding: 0.3em 1em;

	&.has-error,
	&.has-error:focus {
		border-color: #a00e2b !important;
	}

	&.-main {
		min-height: $form-item-xl-size;
		font-size: 1.15em;
	}

	&::-webkit-credentials-auto-fill-button {
		background-color: currentColor;
	}

	&::-webkit-credentials-auto-fill-button:hover {
		background-color: $color-secondary;
	}

	&::-webkit-contacts-auto-fill-button {
		background-color: currentColor;
	}

	&::-webkit-contacts-auto-fill-button:hover {
		background-color: $color-secondary;
	}
}
