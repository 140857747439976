.form {
	padding: 0;
	max-width: 80rem;

	input[type="button"],
	input[type="submit"],
	button {
		cursor: pointer;
	}

	&__errors  {
		list-style-type: none;
		margin: 1.5em 0;
		padding: 0;
		font-size: 1rem;
	}

	&__link {
		font-size: rem(12px);
	}

	&__error  {
		color: rgb(219, 53, 90);
	}

	&__columns {
		display: flex;
		margin-left: -0.5rem;
		margin-right: -0.5rem;

		> .form__section {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			flex-basis: 50%;
		}
	}

	&__section {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 14px;
		position: relative;

		.help-block.text-danger  {
			color: #a00e2b;
			position: absolute;
			right: 0.75em;
			top: 0;
			padding-left: 5px;
			padding-right: 5px;
			transform: translateY(-50%);
			font-size: rem(12px);
			background: linear-gradient(
				to top,
				#1a2025,
				#1a2025 50%,
				transparent 50%,
				transparent
			);
			border-radius: 3px;
			text-align: right;
		}

		.checkbox .help-block.text-danger {
			position: static;
		}

		&.-to-center {
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&.-to-end {
			align-items: flex-end;
			justify-content: flex-end;
			text-align: right;
		}

		&.-spaced {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			> span:last-child {
				width: 100% !important;
			}
		}
	}

	/* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}
